import styled from "styled-components";
import React from "react";
import {TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";
import srcImg1 from "@images/wallet/WhatWalletSectionImage1.png";
import srcImg2 from "@images/wallet/WhatWalletSectionImage2.png";
import srcImg3 from "@images/wallet/WhatWalletSectionImage3.png";
import srcImg4 from "@images/wallet/WhatWalletSectionImage4.png";

export const WhatWalletSection = () => {

    const {width} = useWindowSize();

    return (
        <WhatWalletSectionContainer>

            <WhatWalletSectionItems>
                <WhatWalletSectionItem>
                    <WhatWalletSectionItemImage>
                        <img src={srcImg1} alt={''}/>
                    </WhatWalletSectionItemImage>
                    <WhatWalletSectionItemContent>
                        <WhatWalletSectionItemContentTitle>
                            Автономно
                        </WhatWalletSectionItemContentTitle>
                        <WhatWalletSectionItemContentDescription>
                            Вам не нужно договариваться об оплате и отправлять реквизиты
                        </WhatWalletSectionItemContentDescription>
                    </WhatWalletSectionItemContent>
                </WhatWalletSectionItem>

                <WhatWalletSectionItem>
                    <WhatWalletSectionItemImage>
                        <img src={srcImg2} alt={''}/>
                    </WhatWalletSectionItemImage>
                    <WhatWalletSectionItemContent>
                        <WhatWalletSectionItemContentTitle>
                            Удобно
                        </WhatWalletSectionItemContentTitle>
                        <WhatWalletSectionItemContentDescription>
                            Клиенты оплачивают товары по карте или через СБП                        </WhatWalletSectionItemContentDescription>
                    </WhatWalletSectionItemContent>
                </WhatWalletSectionItem>

                <WhatWalletSectionItem>
                    <WhatWalletSectionItemImage>
                        <img src={srcImg3} alt={''}/>
                    </WhatWalletSectionItemImage>
                    <WhatWalletSectionItemContent>
                        <WhatWalletSectionItemContentTitle>
                            Быстро
                        </WhatWalletSectionItemContentTitle>
                        <WhatWalletSectionItemContentDescription>
                            Деньги моментально попадают на ваш счет в Кошельке                        </WhatWalletSectionItemContentDescription>
                    </WhatWalletSectionItemContent>
                </WhatWalletSectionItem>

                <WhatWalletSectionItem>
                    <WhatWalletSectionItemImage>
                        <img src={srcImg4} alt={''}/>
                    </WhatWalletSectionItemImage>
                    <WhatWalletSectionItemContent>
                        <WhatWalletSectionItemContentTitle>
                            Прозрачно
                        </WhatWalletSectionItemContentTitle>
                        <WhatWalletSectionItemContentDescription>
                            Вам не нужно договариваться об оплате и отправлять реквизиты
                        </WhatWalletSectionItemContentDescription>
                    </WhatWalletSectionItemContent>
                </WhatWalletSectionItem>
            </WhatWalletSectionItems>
        </WhatWalletSectionContainer>
    )
};

const WhatWalletSectionContainer = styled.section`
    display: flex;
    margin-top: 160px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px -19px 40px;
        height: 100%;
        padding: 30px 20px;
    }
`;

const WhatWalletSectionTitle = styled(TypographyUI.H2)`
    margin-bottom: 24px;
    max-width: 820px;
    text-align: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }
`;

const WhatWalletSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-primary);
    max-width: 820px;
    text-align: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }
`;

const WhatWalletSectionItems  = styled.div`
    display: flex;
    gap: 47px;
    flex-wrap: wrap;
    margin-top: 80px;
    justify-content: space-between;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-top: 40px;
    }
`;

const WhatWalletSectionItem = styled.div`
    display: flex;
    gap: 36px;
`;


const WhatWalletSectionItemImage = styled.div`
    width: 65px;
    height: 65px;
    flex: none;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 60px;
        height: 60px;
    }
    img {
        width: 65px;
        height: 65px;
        @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
            width: 60px;
            height: 60px;
        }
    }
`;

const WhatWalletSectionItemContent = styled.div`
    width: 445px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: auto;
    }
`;

const WhatWalletSectionItemContentTitle = styled(TypographyUI.H3)`
    margin-bottom: 16px;
`;

const WhatWalletSectionItemContentDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
`;

